@import-normalize;

.App {
  text-align: center;
  background-image: url('../../images/background.jpg');
  width: 100vw;
  height: 100vh;
  color: white;
}

* {
  box-sizing: border-box;
}
.board {
    position: relative;
    display: flex;
    flex-flow: column;



    box-sizing: border-box;
    height: 100vh;
    padding: 30px;

    @media (max-width: 700px) {
        height: calc(100vh - 68px);
        padding: 30px 10px;
    }

    > div {
        z-index: 10;
    }
}
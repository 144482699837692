.scoreboard {
  font-size: 16px;
  line-height: 1;

  position: absolute;
  bottom: 0;
  left: 0;

  padding: 20px;

  text-align: left;

  border-top: 2px solid #565656;
  border-right: 2px solid #565656;
  background: #047d3b;

  td:nth-of-type(2)
  {
    padding-left: 20px;
  }

  th
  {
    text-align: left;
  }

  @media (max-width: 700px) {
    position: static;
    width: 100%;
    border: 0;
    text-align: center;
    padding: 10px;

    box-shadow: 0 5px 5px rgba(0,0,0,0.2);

    td, tr {
      display: inline-block;
      padding-left: 5px !important;
    }
    tbody {
      tr {
        margin: 0 20px;
      }
    }
  }
}
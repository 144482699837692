.dealer {
  &__cards {
    position: relative;
    height: 20%;
    max-width: 1400px;;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__name {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
  }
}
.game-status {
  margin-top: auto;
  margin-bottom: auto;

  &__text {
    margin-top: 20px;
    font-size: 16px;
    @media (min-width: 700px) {
      font-size: 20px;
      margin-top: 30px;
    }
  }
}

.card {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 25%;
    box-shadow: 0 5px 2px rgba(0,0,0,0.8);
    left: calc(50% - (25% / 2));
    transition: transform .2s ease;
    border-radius: 10px;

    @media (min-width: 500px) {
        width: 20%;
        left: calc(50% - (20% / 2));
    }
    @media (min-width: 750px) {
        width: 15%;
        left: calc(50% - (15% / 2));
    }
    @media (min-width: 1000px) {
        width: 12%;
        left: calc(50% - (12% / 2));
    }


    // Will make cards always the right height based on width
    &:after {
        content: '';
        display: block;
        padding-bottom: 138%;
    }


    &:nth-of-type(1) {
        .player & {
            transform: translateX(-100%) rotate(-8deg);
        }
        .player:not(.player--end-game) & {
            &:hover {
                transform: translateX(-100%) translateY(-20px) rotate(-8deg);
            }
        }
        .dealer & {
            transform: translateX(100%) rotate(-8deg);
        }
    }


    &:nth-of-type(2) {
        .player & {
            transform: translateX(-50%) rotate(-4deg) translateY(-8px);
        }
        .player:not(.player--end-game) & {
            &:hover {
                transform: translateX(-50%) rotate(-4deg) translateY(-28px);
            }
        }
        .dealer & {
            transform: translateX(50%) rotate(-4deg) translateY(8px);
        }
    }

    &:nth-of-type(3) {
        .player & {
            transform: translateY(-12px);
        }
        .player:not(.player--end-game) & {
            &:hover {
                transform: translateY(-32px);
            }
        }
        .dealer & {
            transform: translateY(12px);
        }
    }

    &:nth-of-type(4) {
        .player & {
            transform: translateX(50%) rotate(4deg) translateY(-8px);
        }
        .player:not(.player--end-game) & {
            &:hover {
                transform: translateX(50%) rotate(4deg) translateY(-28px);
            }
        }
        .dealer & {
            transform: translateX(-50%) rotate(4deg) translateY(8px);
        }
    }

    &:nth-of-type(5) {
        .player & {
            transform: translateX(100%) rotate(8deg);
        }
        .player:not(.player--end-game) & {
            &:hover {
                transform: translateX(100%) translateY(-20px) rotate(8deg);
            }
        }
        .dealer & {
            transform: translateX(-100%) rotate(8deg);
        }
    }

    .player & {
        bottom: 0;
        cursor: pointer;
    }
}


.card {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: url('../../images/cards/back.svg');

    .dealer:not(.dealer--show-cards) & {
        background-image: url('../../images/cards/back.svg');
    }

    &--2c {  background-image: url('../../images/cards/2c.svg');}
    &--3c {  background-image: url('../../images/cards/3c.svg');}
    &--4c {  background-image: url('../../images/cards/4c.svg');}
    &--5c {  background-image: url('../../images/cards/5c.svg');}
    &--6c {  background-image: url('../../images/cards/6c.svg');}
    &--7c {  background-image: url('../../images/cards/7c.svg');}
    &--8c {  background-image: url('../../images/cards/8c.svg');}
    &--9c {  background-image: url('../../images/cards/9c.svg');}
    &--Tc {  background-image: url('../../images/cards/Tc.svg');}
    &--Jc {  background-image: url('../../images/cards/Jc.svg');}
    &--Qc {  background-image: url('../../images/cards/Qc.svg');}
    &--Kc {  background-image: url('../../images/cards/Kc.svg');}
    &--Ac {  background-image: url('../../images/cards/Ac.svg');}
    
    &--2s {  background-image: url('../../images/cards/2s.svg');}
    &--3s {  background-image: url('../../images/cards/3s.svg');}
    &--4s {  background-image: url('../../images/cards/4s.svg');}
    &--5s {  background-image: url('../../images/cards/5s.svg');}
    &--6s {  background-image: url('../../images/cards/6s.svg');}
    &--7s {  background-image: url('../../images/cards/7s.svg');}
    &--8s {  background-image: url('../../images/cards/8s.svg');}
    &--9s {  background-image: url('../../images/cards/9s.svg');}
    &--Ts {  background-image: url('../../images/cards/Ts.svg');}
    &--Js {  background-image: url('../../images/cards/Js.svg');}
    &--Qs {  background-image: url('../../images/cards/Qs.svg');}
    &--Ks {  background-image: url('../../images/cards/Ks.svg');}
    &--As {  background-image: url('../../images/cards/As.svg');}
    
    &--2h {  background-image: url('../../images/cards/2h.svg');}
    &--3h {  background-image: url('../../images/cards/3h.svg');}
    &--4h {  background-image: url('../../images/cards/4h.svg');}
    &--5h {  background-image: url('../../images/cards/5h.svg');}
    &--6h {  background-image: url('../../images/cards/6h.svg');}
    &--7h {  background-image: url('../../images/cards/7h.svg');}
    &--8h {  background-image: url('../../images/cards/8h.svg');}
    &--9h {  background-image: url('../../images/cards/9h.svg');}
    &--Th {  background-image: url('../../images/cards/Th.svg');}
    &--Jh {  background-image: url('../../images/cards/Jh.svg');}
    &--Qh {  background-image: url('../../images/cards/Qh.svg');}
    &--Kh {  background-image: url('../../images/cards/Kh.svg');}
    &--Ah {  background-image: url('../../images/cards/Ah.svg');}
    
    &--2d {  background-image: url('../../images/cards/2d.svg');}
    &--3d {  background-image: url('../../images/cards/3d.svg');}
    &--4d {  background-image: url('../../images/cards/4d.svg');}
    &--5d {  background-image: url('../../images/cards/5d.svg');}
    &--6d {  background-image: url('../../images/cards/6d.svg');}
    &--7d {  background-image: url('../../images/cards/7d.svg');}
    &--8d {  background-image: url('../../images/cards/8d.svg');}
    &--9d {  background-image: url('../../images/cards/9d.svg');}
    &--Td {  background-image: url('../../images/cards/Td.svg');}
    &--Jd {  background-image: url('../../images/cards/Jd.svg');}
    &--Qd {  background-image: url('../../images/cards/Qd.svg');}
    &--Kd {  background-image: url('../../images/cards/Kd.svg');}
    &--Ad {  background-image: url('../../images/cards/Ad.svg');}
}
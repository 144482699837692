.btn {

  display: inline-block;

  margin-left: auto;
  padding: 12px 18px;

  cursor: pointer;
  transition: transform .2s ease;
  text-transform: uppercase;

  font-weight: bold;
  color: white;
  border-radius: 2px;
  background: #bd3434;
  font-size: 14px;
  box-shadow: 0 5px 6px rgba(0, 0, 0, .3);

  @media (min-width: 700px) {
    padding: 18px 24px;
    font-size: 18px;
  }

  &:hover {
    transform: translateY(-3px);

    box-shadow: 0 8px 6px rgba(0, 0, 0, .3);
  }
}